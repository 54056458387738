import { SeoData } from '@shared/types';

export const replaceValue = (str: string, replacedValue: string, newValue: string) => {
  return str?.replace(new RegExp(`{{\\s*${replacedValue}\\s*}}`, 'gim'), newValue) || '';
};

export const replaceDataValues = (seoData: SeoData, values: Record<string, string>) => {
  return Object.entries(seoData).reduce((prev, [key, str]) => {
    Object.entries(values).forEach(([keyword, newValue]) => {
      str = replaceValue(str, keyword, newValue);
    });
    return { ...prev, [key]: str };
  }, {});
};
