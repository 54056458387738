import { DatePipe, DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Entity } from '@app/entity/entity.types';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSeoMetaTagAttr, SeoSocialShareService } from './ngx-seo';
import { environment } from 'src/environments/environment';
import { OG_DEFAULT_IMAGE, OpenGraphTypes } from '../const/opengraph';
import { COMPANY_NAME, COPYRIGHT, CURRENCY_DEFAULT } from '../const/settings';
import {
  Article,
  Country,
  FirmDetails,
  Law,
  LawType,
  LawyerDetails,
  Price,
  Question,
  Service,
  Term,
  Translation
} from '../models';
import {
  Crumbs,
  RouteData,
  SchemaOrgArticle,
  SchemaOrgLegislation,
  SchemaOrgOrganization,
  SchemaOrgOrganizationType,
  SchemaOrgParams,
  SchemaOrgPerson,
  SchemaOrgProduct,
  SchemaOrgQuestion,
  SchemaOrgTerm,
  SchemaOrgWebPage,
  Sections,
  SeoData
} from '../types';
import { getSectionLink } from '../utils';
import {
  getDefaultArticleImage,
  getDefaultLawImage,
  getDefaultPublisherImage,
  getDefaultTermImage,
  putToHtmlSchemaOrg,
  stripHtml
} from '../utils/schema-org/schema-org';
import { replaceDataValues, replaceValue } from '@shared/utils/seo';
import { STATE_TRANSLATE_SEO } from '@shared/const/i18n';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  private baseUrl: string;
  private currentUrl: string;
  private jsonDataSeo?: Record<string, any> | null;
  private breadcrumbs?: Crumbs[];
  private schemaOrgParams: SchemaOrgParams;

  constructor(
    private readonly seo: SeoSocialShareService,
    @Inject(DOCUMENT) private doc: any,
    private router: Router,
    private localize: LocalizeRouterService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.baseUrl = environment['seo']['baseUrl'] || this.doc.location.origin;
    this.currentUrl = `${this.baseUrl}${this.router.url}`;
    const key = makeStateKey<Record<string, any>>(STATE_TRANSLATE_SEO + this.translate.currentLang);
    this.jsonDataSeo = this.transferState.get<Record<string, any> | null>(key, null);

    if (isPlatformServer(this.platformId)) {
      const getChildData = (data: ActivatedRouteSnapshot): RouteData => {
        if (data.children.length) return getChildData(data.children[0]);
        else return data.data;
      };

      if (!environment.production) this.setIgnoreRobots();

      this.breadcrumbs = getChildData(this.route.snapshot).breadcrumbs?.filter((item) => item !== Crumbs.Home);
      this.setSchemaOrgBreadcrumbs(this.breadcrumbs);
    }

    this.schemaOrgParams = {
      baseUrl: this.baseUrl,
      currentUrl: this.currentUrl,
      addBreadcrumbs: !!this.breadcrumbs?.length
    };
  }

  setUrl(
    section: Sections | undefined,
    id: string,
    translations: Translation,
    alias: string,
    currentLang = this.localize.parser.currentLang,
    language?: string
  ) {
    if (!section || isPlatformBrowser(this.platformId)) return;

    const defaultLang = this.localize.parser.defaultLang;

    const newPath = this.router.serializeUrl(
      this.router.createUrlTree(getSectionLink(section, id, translations[language || currentLang]?.alias || alias))
    );

    const prefix = !language
      ? currentLang === defaultLang
        ? ''
        : currentLang
      : language === defaultLang
        ? ''
        : language;

    const url = `${this.baseUrl}${!!prefix ? '/' + prefix : ''}${newPath}`;

    this.currentUrl = url;
    this.seo.setUrl(url);

    if (this.currentUrl !== this.schemaOrgParams.currentUrl) this.setSchemaOrgBreadcrumbs(this.breadcrumbs);
    this.schemaOrgParams.currentUrl = url;

    this.localize.parser.locales
      .filter((lang) => lang !== currentLang)
      .forEach((lang) => {
        if (!(Object.keys(translations || []).length === 1)) {
          const alias = translations[lang || currentLang]?.alias;
          if (alias) {
            const pathLocale = this.router.serializeUrl(this.router.createUrlTree(getSectionLink(section, id, alias)));

            this.seo.setLanguageAlternativeUrl(
              lang,
              `${this.baseUrl}${lang !== defaultLang ? '/' + lang : ''}${pathLocale}`
            );
          }
        } else {
          this.seo.setLanguageAlternativeUrl(lang, undefined);
        }
      });

    const aliasEn = translations[defaultLang]?.alias || alias;
    this.seo.setLanguageAlternativeUrl(
      'x-default',
      `${this.baseUrl}${this.router.serializeUrl(this.router.createUrlTree(getSectionLink(section, id, aliasEn)))}`
    );
  }

  setData(seoData: SeoData | Record<string, void> = {}) {
    const { title, description, keywords } = seoData;
    if (title) this.seo.setTitle(title);
    if (description) this.seo.setDescription(description);
    if (keywords) this.seo.setKeywords(keywords);
  }

  setAlternateCoutriesUrl(section: Sections | undefined, country: string, queryParams: Record<string, string>) {
    if (isPlatformBrowser(this.platformId)) return;

    const defaultLang = this.localize.parser.defaultLang;
    const lCountry = country.toLowerCase();
    const enUrl = `${this.baseUrl}${this.router.serializeUrl(this.router.createUrlTree(getSectionLink(section), { queryParams }))}`;

    this.localize.parser.locales.forEach((language) => {
      const langUrl = this.router.serializeUrl(
        this.router.createUrlTree(getSectionLink(section, lCountry), { queryParams })
      );
      this.seo.setLanguageAlternativeUrl(
        `${language}-${lCountry}`,
        `${this.baseUrl}${language !== defaultLang ? '/' + language : ''}${langUrl}`
      );
    });
    this.seo.setLanguageAlternativeUrl('x-default', enUrl);
  }

  setPrevNext(name: 'next' | 'prev', path: string, page: number) {
    if (isPlatformBrowser(this.platformId)) return;

    const url = `${this.baseUrl}${this.router.serializeUrl(
      this.router.createUrlTree([path], { ...(page > 1 && { queryParams: { page } }) })
    )}`;

    const selector = `link[rel='${name}']`;
    const domElement = this.doc.head.querySelector(selector);
    if (domElement) {
      this.doc.head.removeChild(domElement);
    }
    if (!page) return;

    if (url && url.length) {
      const link = this.doc.createElement('link');
      link.setAttribute('rel', name);
      link.setAttribute('href', url);
      this.doc.head.appendChild(link);
    }
  }

  setCopyright() {
    if (isPlatformBrowser(this.platformId)) return;

    this.seo.setMetaTag({
      attr: NgxSeoMetaTagAttr.name,
      attrValue: 'copyright',
      value: `${new Date().getFullYear()} © ${COPYRIGHT}`
    });
  }

  setIgnoreRobots() {
    if (isPlatformBrowser(this.platformId)) return;

    this.seo.setMetaTag({
      attr: NgxSeoMetaTagAttr.name,
      attrValue: 'robots',
      value: 'noindex, nofollow'
    });
  }

  setSocial() {
    if (isPlatformBrowser(this.platformId)) return;

    this.seo.setFbAppId(environment.tokens['facebook']);
  }

  splitName(name: string) {
    const _names: string[] = [];

    (name || '').split(' ').forEach((value, key) => {
      if (key < 2) {
        _names[key] = value;
        return;
      }
      _names[1] += ' ' + value;
    });

    return _names;
  }

  setOGTag(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) return;

    this.seo.setMetaTag({
      attr: NgxSeoMetaTagAttr.property,
      attrValue: key,
      value
    });
  }

  setOGImage(url?: string, width?: number, height?: number) {
    const { url: defaultUrl, width: defaultWidth, height: defaultHeight } = OG_DEFAULT_IMAGE;

    this.seo.setImage(`${!url ? this.baseUrl : ''}${url || defaultUrl}`, {
      width: width || defaultWidth,
      height: height || defaultHeight
    });
  }

  setOG(typeOG: OpenGraphTypes = OpenGraphTypes.Website) {
    if (isPlatformBrowser(this.platformId)) return;

    this.seo.setType(typeOG);
    this.setOGImage();
    this.setOGTag('og:site_name', this.translate.instant('global.info.site.name'));
    this.setOGTag('og:locale', this.translate.instant('global.info.locale'));
  }

  setOGProduct(title: string, brand: string, category: string, price: Price, link: string) {
    if (isPlatformBrowser(this.platformId)) return;

    this.setOGTag('og:title', title);
    this.setOGTag(`${OpenGraphTypes.Product}:plural_title`, title);
    this.setOGTag(`${OpenGraphTypes.Product}:age_group`, 'adult');
    this.setOGTag(`${OpenGraphTypes.Product}:availability`, 'oos');
    this.setOGTag(`${OpenGraphTypes.Product}:brand`, brand);
    this.setOGTag(`${OpenGraphTypes.Product}:category`, category);
    this.setOGTag(`${OpenGraphTypes.Product}:price:amount`, String(price.amount));
    this.setOGTag(`${OpenGraphTypes.Product}:price:currency`, price.currency);
    this.setOGTag(`${OpenGraphTypes.Product}:product_link`, this.baseUrl + link);
  }

  setOGProfile(isFirm: boolean, name?: string, appeal?: number, username?: string) {
    if (!name || isPlatformBrowser(this.platformId)) return;
    const names = isFirm ? [name] : this.splitName(name);

    this.setOGTag(`${OpenGraphTypes.Profile}:first_name`, names[0]);
    if (!isFirm) this.setOGTag(`${OpenGraphTypes.Profile}:last_name`, names[1]);
    if (username) this.setOGTag(`${OpenGraphTypes.Profile}:username`, username);
    if (!isFirm) this.setOGTag(`${OpenGraphTypes.Profile}:gender`, appeal === 1 || appeal === 2 ? 'female' : 'male');
  }

  setOGArticle(article: Entity, section: Sections | undefined, title: string | undefined) {
    if (!section || isPlatformBrowser(this.platformId)) return;

    const getSectionTitle = (section: Sections) => {
      const titles: Partial<Record<Sections, string>> = {
        [Sections.Questions]: 'questionPage.title',
        [Sections.CompanyBlog]: 'companyBlogPage.title',
        [Sections.LegalBlog]: 'legalBlogPage.title',
        [Sections.LegalDictionary]: 'legalDictionaryPage.title',
        [Sections.Laws]: 'lawsPage.title'
      };

      const title = titles[section];
      return title ? this.translate.instant(title) : '';
    };

    if ('date' in article) {
      this.setOGTag(
        `${OpenGraphTypes.Article}:published_time`,
        new Date(article.date.created * 1000)?.toISOString() || ''
      );
      this.setOGTag(
        `${OpenGraphTypes.Article}:modified_time`,
        new Date(article.date.modified * 1000)?.toISOString() || ''
      );
    }

    if (title) this.setOGTag('og:title', title);
    this.setOGTag(`${OpenGraphTypes.Article}:section`, getSectionTitle(section));

    if ('author' in article) {
      const names = this.splitName(article.author.name);

      this.setOGTag(`${OpenGraphTypes.Article}:author:first_name`, names[0]);
      this.setOGTag(`${OpenGraphTypes.Article}:author:last_name`, names[1]);
      this.setOGTag(`${OpenGraphTypes.Article}:author:username`, article.author.alias);
    }
  }

  getlawyerType(type: number | null) {
    const lawyerType = this.translate.instant('global.lawyerType');
    return lawyerType[type || 0] || lawyerType[0];
  }

  setSchemaOrgWebPage(data: SeoData) {
    if (isPlatformBrowser(this.platformId)) return;

    const { title: name = '', description = '', keywords = '' } = data;
    const preparedData: SchemaOrgWebPage = { name, description, keywords };

    import('@shared/utils/schema-org/web-page').then((module) => {
      putToHtmlSchemaOrg(this.doc, module.webPage(preparedData, this.translate, this.schemaOrgParams));
    });
  }

  setSchemaOrgBreadcrumbs(items?: Crumbs[]) {
    if (!items || isPlatformBrowser(this.platformId)) return;

    import('@shared/utils/schema-org/breadcrumbs').then((module) => {
      putToHtmlSchemaOrg(
        this.doc,
        module.breadcrumbs(items, this.translate, this.schemaOrgParams),
        'head',
        'breadcrumbs'
      );
    });
  }

  setSchemaOrgPerson(lawyer: LawyerDetails) {
    if (isPlatformBrowser(this.platformId)) return;

    const country = lawyer.office.country.title;
    const locality = lawyer.office.state.title;

    const data: SchemaOrgPerson = {
      name: lawyer.name,
      description: lawyer.seo.description,
      jobTitle: this.getlawyerType(lawyer.type),
      address: { country, locality },
      award: lawyer.awards,
      sameAs: lawyer.linkedin,
      org: {
        name: lawyer?.firm?.name || '',
        legalName: lawyer?.firm?.name || '',
        address: {
          country,
          locality,
          street: lawyer.office?.address || ''
        }
      }
    };

    Promise.all([import('@shared/utils/schema-org/person'), import('@shared/utils/schema-org/profile-page')]).then(
      (module) => {
        const person = module[0].person(data, this.schemaOrgParams);
        putToHtmlSchemaOrg(this.doc, module[1].profilePage(person, this.translate, this.schemaOrgParams));
      }
    );
  }

  setSchemaOrgLegalService(lawFirm: FirmDetails | undefined) {
    if (!lawFirm || isPlatformBrowser(this.platformId)) return;

    const country = lawFirm.offices.main.country.title;
    const locality = lawFirm.offices.main.state.title;
    const data: SchemaOrgOrganization = {
      orgType: SchemaOrgOrganizationType.LegalService,
      name: lawFirm.name,
      description: lawFirm.seo.description,
      address: { country, locality, street: lawFirm.offices?.main?.location?.address || '' },
      legalName: lawFirm.name,
      logoUrl: lawFirm.logo.url,
      imageUrl: lawFirm.photo.url,
      telephone: lawFirm.offices.main.phones.phone,
      faxNumber: lawFirm.offices.main.phones.fax || ''
    };

    Promise.all([
      import('@shared/utils/schema-org/organization'),
      import('@shared/utils/schema-org/profile-page')
    ]).then((module) => {
      const legalService = module[0].organization(data, this.schemaOrgParams);
      putToHtmlSchemaOrg(this.doc, module[1].profilePage(legalService, this.translate, this.schemaOrgParams));
    });
  }

  setSchemaOrgQuestion(question: Question) {
    if (isPlatformBrowser(this.platformId)) return;

    const topAnswer = question.answers[question.answers.length - 1];
    const answer = topAnswer.list[0];

    const lawyerUrl = `${this.baseUrl}${this.localize.translateRoute(
      this.router.serializeUrl(
        this.router.createUrlTree(getSectionLink(Sections.Lawyers, topAnswer.lawyer.id, topAnswer.lawyer.alias))
      )
    )}`;

    const firmUrl = `${this.baseUrl}${this.localize.translateRoute(
      this.router.serializeUrl(
        this.router.createUrlTree(
          getSectionLink(Sections.LawFirms, topAnswer.lawyer?.firm?.id || 0, topAnswer.lawyer?.firm?.alias || 0)
        )
      )
    )}`;

    const data: SchemaOrgQuestion = {
      name: question.seo.title,
      keywords: question.seo.keywords,
      commentCount: String(topAnswer.list.length - 1),
      answerCount: String(question.answers.length),
      dateCreated: this.datePipe.transform(question.date.created * 1000, 'mediumDate') || '',
      text: question.seo.description,
      language: question.language.code,
      answer: {
        dateCreated: this.datePipe.transform(answer.date * 1000, 'mediumDate') || '',
        inLanguage: question.language.code,
        text: stripHtml(this.doc, answer.description),
        url: this.currentUrl ? `${this.currentUrl}#${topAnswer.id}` : '',
        author: {
          name: topAnswer.lawyer.name,
          url: lawyerUrl,
          jobTitle: this.getlawyerType(topAnswer.lawyer.position),
          worksFor: {
            name: topAnswer.lawyer?.firm?.name || '',
            legalName: topAnswer.lawyer?.firm?.name || '',
            url: firmUrl
          },
          sameAs: topAnswer.lawyer.linkedin
        }
      },
      address: {
        country: question.country.title,
        locality: question.state.id === 0 ? '' : question.state.title
      }
    };
    import('@shared/utils/schema-org/qa-page').then((module) => {
      putToHtmlSchemaOrg(this.doc, module.qaPage(data, this.schemaOrgParams));
    });
  }

  defaultSchemaOrgArticle(article: Article | Law | Term) {
    return {
      name: article.seo.title ? article.seo.title : article.title,
      description: article.seo.description,
      keywords: article.seo.keywords,
      language: article.language.code,
      ...('date' in article && {
        datePublished: this.datePipe.transform(article.date.created * 1000, 'mediumDate') || ''
      }),
      ...('date' in article && {
        dateModified: this.datePipe.transform(article.date.modified * 1000, 'mediumDate') || ''
      })
    };
  }

  setSchemaOrgArticleBlog(article: Article | undefined, isCompanyBlog?: boolean) {
    if (!article || isPlatformBrowser(this.platformId)) return;

    const isAttory = isCompanyBlog || article.author.attory;
    const isFirm = article.author.type === LawType.Firm;

    const authorUrl = isAttory
      ? this.baseUrl + this.localize.translateRoute('/about-us')
      : `${this.baseUrl}${this.localize.translateRoute(
          this.router.serializeUrl(
            this.router.createUrlTree(
              getSectionLink(isFirm ? Sections.LawFirms : Sections.Lawyers, article.author.id, article.author.alias)
            )
          )
        )}`;

    const data: SchemaOrgArticle = {
      ...this.defaultSchemaOrgArticle(article),
      author: {
        name: article.author.name,
        url: authorUrl,
        email: article.author.email || '',
        jobTitle: this.getlawyerType(article.author.position),
        sameAs: article.author.linkedin,
        isFirm,
        isAttory
      },
      address: { country: article.country.title, locality: article.state.id === 0 ? '' : article.state.title },
      publisher: {
        name: article.firm.name,
        legalName: article.firm.name,
        ...(article.firm.logo && { logo: { url: this.baseUrl + article.firm.logo } })
      },
      headline: article.title,
      image: article.image && article.image.url ? { url: article.image.url } : getDefaultArticleImage(this.baseUrl)
    };

    import('@shared/utils/schema-org/article').then((module) => {
      putToHtmlSchemaOrg(this.doc, module.article(data, this.translate, this.schemaOrgParams));
    });
  }

  setSchemaOrgLegislation(law: Law | undefined) {
    if (!law || isPlatformBrowser(this.platformId)) return;

    const data: SchemaOrgLegislation = {
      ...this.defaultSchemaOrgArticle(law),
      publisher: {
        name: COMPANY_NAME,
        logo: getDefaultPublisherImage(this.baseUrl)
      },
      headline: law.schema.title,
      image: getDefaultLawImage(this.baseUrl)
    };

    import('@shared/utils/schema-org/legislation').then((module) => {
      putToHtmlSchemaOrg(this.doc, module.legislation(data, this.schemaOrgParams));
    });
  }

  setSchemaOrgTerm(law: Term | undefined) {
    if (!law || isPlatformBrowser(this.platformId)) return;

    const data: SchemaOrgTerm = {
      ...this.defaultSchemaOrgArticle(law),
      publisher: {
        name: COMPANY_NAME,
        logo: getDefaultPublisherImage(this.baseUrl)
      },
      image: getDefaultTermImage(this.baseUrl)
    };

    import('@shared/utils/schema-org/term').then((module) => {
      putToHtmlSchemaOrg(this.doc, module.term(data, this.schemaOrgParams));
    });
  }

  setSchemaOrgProduct(service: Service | undefined) {
    if (!service || isPlatformBrowser(this.platformId)) return;

    const getReviewsScore = () => {
      if (!service.reviews || service.reviews.length < 2) return 0;

      const sum = service.reviews.reduce(function (result, review) {
        return result + review.value;
      }, 0);

      return Math.round((sum / service.reviews.length) * 10) / 10;
    };

    const data: SchemaOrgProduct = {
      name: service.seo.title,
      image: service.category?.image || '',
      description: service.seo.description,
      keywords: service.seo.description,
      offers: {
        price: String(service.price.amount),
        priceCurrency: service.price.currency || CURRENCY_DEFAULT,
        priceValidUntil: this.datePipe.transform(Date.now(), 'yyyy-MM-dd') || '',
        availability: 'OnlineOnly'
      },
      aggregateRating: {
        ratingValue: String(getReviewsScore()),
        reviewCount: String(service.reviews.length)
      },
      review: service.reviews.slice(0, 5).map((review) => ({
        datePublished: this.datePipe.transform(review.date * 1000, 'yyyy-MM-dd') || '',
        reviewBody: review.text,
        reviewAuthor: review.client.name,
        reviewRating: {
          bestRating: '5',
          ratingValue: '' + review.value,
          worstRating: '1'
        }
      }))
    };

    Promise.all([import('@shared/utils/schema-org/product'), import('@shared/utils/schema-org/item-page')]).then(
      (module) => {
        const product = module[0].product(data, this.schemaOrgParams);
        putToHtmlSchemaOrg(this.doc, module[1].itemPage(data, product, this.translate, this.schemaOrgParams));
      }
    );
  }

  setTexts(translateKey: string | null | undefined, country?: Country) {
    if (translateKey && this.jsonDataSeo?.[translateKey]) {
      let seoData = this.jsonDataSeo?.[translateKey];

      const computed = this.jsonDataSeo?.['computed'];

      const globalCountries = computed?.['country'];
      const localCountries = computed?.[translateKey]?.['country'];

      if (globalCountries) {
        let rootCountryTitle = country?.title || globalCountries?.['region'] || '';

        if (country?.shortCode) {
          rootCountryTitle =
            globalCountries[globalCountries[country.shortCode] ? country.shortCode : 'others'] || country?.title;
        }

        const countries = localCountries ? { ...globalCountries, ...localCountries } : globalCountries;
        let countryTitle = country?.title || countries['region'];

        if (country?.shortCode) {
          countryTitle = countries[countries[country.shortCode] ? country.shortCode : 'others'] || country?.title;
        }

        countryTitle = replaceValue(countryTitle, 'country', rootCountryTitle);

        seoData = replaceDataValues(seoData, { country: countryTitle, rootCountry: rootCountryTitle });
      }
      this.setData(seoData);
    }
  }

  setTwitterData() {
    this.seo.setTwitterSiteCreator(this.translate.instant('global.info.site.name'));
    this.seo.setTwitterCard('summary_large_image');
  }
}
